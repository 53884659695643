import React, { useState, useEffect } from 'react';
import { fetchCallSessions } from '../services/callSessionService';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FullPageSpinner from '../components/FullPageSpinner';
import Toast from '../components/Toast';

const CallsPage: React.FC = () => {
  const [calls, setCalls] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10); // Default size of records per page
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [toastVisible, setToastVisible] = useState(false);
  const [sort, setSort] = useState('createdAt'); // Default sorting column
  const [order, setOrder] = useState('desc'); // Default sorting order
  const navigate = useNavigate();

  useEffect(() => {
    const loadCallSessions = async () => {
      setLoading(true);
      try {
        const data = await fetchCallSessions(page, size, sort, order);
        setCalls(data.rows);
        setTotalPages(Math.ceil(data.count / size));
      } catch (error) {
        setError('Error fetching call sessions.');
        setToastVisible(true);
      } finally {
        setLoading(false);
      }
    };

    loadCallSessions();
  }, [page, size, sort, order]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSort = (column: string) => {
    const newOrder = sort === column && order === 'asc' ? 'desc' : 'asc';
    setSort(column);
    setOrder(newOrder);
  };

  const handleInspectCall = (id: string) => {
    navigate(`/home/calls/${id}`);
  };

  const handleCloseToast = () => {
    setToastVisible(false);
  };

  return (
    <div className="bg-gray-100 p-4 relative">
      {loading && <FullPageSpinner />}
      {error && (
        <Toast
          message={error}
          severity="error"
          isVisible={toastVisible}
          onClose={handleCloseToast}
        />
      )}
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6">Call Sessions</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th
                    className="border-b px-4 py-2 cursor-pointer"
                    onClick={() => handleSort('callerNumber')}
                  >
                    Caller Number {sort === 'callerNumber' && (order === 'asc' ? '↑' : '↓')}
                  </th>
                  <th
                    className="border-b px-4 py-2 cursor-pointer text-center"
                    onClick={() => handleSort('calledNumber')}
                  >
                    Called Number {sort === 'calledNumber' && (order === 'asc' ? '↑' : '↓')}
                  </th>
                  <th
                    className="border-b px-4 py-2 cursor-pointer text-center"
                    onClick={() => handleSort('language')}
                  >
                    Language {sort === 'language' && (order === 'asc' ? '↑' : '↓')}
                  </th>
                  <th
                    className="border-b px-4 py-2 cursor-pointer text-center"
                    onClick={() => handleSort('createdAt')}
                  >
                    Created At {sort === 'createdAt' && (order === 'asc' ? '↑' : '↓')}
                  </th>
                  <th
                    className="border-b px-4 py-2 cursor-pointer text-center"
                    onClick={() => handleSort('assistant')}
                  >
                    Assistant {sort === 'assistant' && (order === 'asc' ? '↑' : '↓')}
                  </th>
                  <th className="border-b px-4 py-2 text-end"></th>
                </tr>
              </thead>
              <tbody>
                {calls.map((call: any) => (
                  <tr key={call.id}>
                    <td className="border-b px-4 py-2">{call.callerNumber}</td>
                    <td className="border-b px-4 py-2 text-center">{call.calledNumber}</td>
                    <td className="border-b px-4 py-2 text-center">{call.language}</td>
                    <td className="border-b px-4 py-2 text-center">{new Date(call.createdAt).toLocaleString()}</td>
                    <td className="border-b px-4 py-2 text-center">{call.assistant}</td>
                    <td className="border-b px-4 py-2 text-center">
                      <button
                        onClick={() => handleInspectCall(call.id)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Updated Pagination Controls */}
            <div className="flex justify-end items-center mt-4 space-x-4">
              <div>
                <label className="text-gray-600">Records per page:</label>
                <select
                  value={size}
                  onChange={(e) => setSize(Number(e.target.value))}
                  className="ml-2 border rounded-md px-2 py-1"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="text-gray-600 hover:text-gray-800 disabled:text-gray-400"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => handlePageChange(i + 1)}
                    className={`mx-1 px-2 py-1 rounded ${
                      page === i + 1 ? 'bg-teal-500 text-white' : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  className="text-gray-600 hover:text-gray-800 disabled:text-gray-400"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
              <div className="text-gray-600">
                Page {page} of {totalPages}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CallsPage;
