import React from 'react';
import { GridLoader } from 'react-spinners';

const FullPageSpinner: React.FC = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
      <GridLoader color="#00bcd4" size={15} />
    </div>
  );
};

export default FullPageSpinner;
