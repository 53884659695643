import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCallSessions = async (page: number, size: number, sort: string, order: string) => {
  const response = await axios.get(`${API_BASE_URL}/call-session/page`, {
    params: { page, size, sort, order },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response.data;
};

export const fetchCallSessionById = async (id: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/call-session/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch call session details');
  }
};
