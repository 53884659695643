import axios from './api';

interface AppointmentResponse {
  count: number;
  rows: Appointment[];
}

interface Appointment {
  id: number;
  date: string;
  time: string;
  name: string;
  propertyId: string;
  callerPhoneNumber: string;
  status?: string;
  createdAt: string;
  updatedAt: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAppointments = async (
  page: number,
  size: number,
  sortBy: string,
  sortOrder: 'asc' | 'desc'
): Promise<AppointmentResponse> => {
  const response = await axios.get(`${API_BASE_URL}/appointment/page`, {
    params: { page, size, sort: sortBy, order: sortOrder },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response.data;
};
