import React from 'react';

interface ToastProps {
  message: string;
  severity: 'success' | 'error';
  isVisible: boolean;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, severity, isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className={`fixed top-5 right-5 p-4 rounded-lg shadow-lg transition-opacity duration-300 ${
      severity === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
    }`}>
      <div className="flex justify-between items-center">
        <span>{message}</span>
        <button onClick={onClose} className="ml-4">
          &times;
        </button>
      </div>
    </div>
  );
};

export default Toast;
