import React from 'react';
import { useFormik } from 'formik';
import axios from '../services/api';
import { authenticate } from '../services/authService';

const LoginPage = () => {
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        const response = await authenticate(values);
        localStorage.setItem('accessToken', response.accessToken);
        window.location.href = '/home';
      } catch (error) {
        console.error('Login failed', error);
      }
    },
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-teal-400 to-blue-500">
      <form onSubmit={formik.handleSubmit} className="bg-white p-8 rounded-xl shadow-2xl w-96">
        <h2 className="text-3xl font-extrabold mb-6 text-center text-gray-800">Login</h2>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-600 font-semibold mb-2">Username</label>
          <input
            id="username"
            name="username"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.username}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-600 font-semibold mb-2">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
          />
        </div>
        <button
          type="submit"
          className="bg-teal-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-teal-600 transition duration-200 w-full"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
