import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchSettings = async () => {
  const response = await axios.get(`${API_BASE_URL}/config/all`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response.data;
};

export const saveSettings = async (data: any) => {
  const response = await axios.put(`${API_BASE_URL}/config`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response.data;
};
