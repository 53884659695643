import React, { useEffect, useState } from 'react';
import { fetchAppointments } from '../services/appointmentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FullPageSpinner from '../components/FullPageSpinner';
import Toast from '../components/Toast';

interface Appointment {
  id: number;
  date: string;
  time: string;
  name: string;
  propertyId: string;
  callerPhoneNumber: string;
  status?: string;
}

const statusColors: Record<string, string> = {
  Booked: 'bg-yellow-100 text-yellow-800',
  Confirmed: 'bg-blue-100 text-blue-800',
  Done: 'bg-green-100 text-green-800',
};

const AppointmentsPage: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getAppointments = async () => {
      try {
        setLoading(true);
        const { rows, count } = await fetchAppointments(currentPage, pageSize, sortBy, sortOrder);
        setAppointments(rows);
        setTotalPages(Math.ceil(count / pageSize));
      } catch (error) {
        setError('Failed to fetch appointments.');
      } finally {
        setLoading(false);
      }
    };

    getAppointments();
  }, [currentPage, pageSize, sortBy, sortOrder]);

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleCloseToast = () => {
    setError(null);
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md relative">
      {error && (
        <Toast
          message={error}
          severity="error"
          isVisible={!!error}
          onClose={handleCloseToast}
        />
      )}

      <h2 className="text-2xl font-bold mb-4">Appointments</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 border-b cursor-pointer" onClick={() => handleSort('date')}>
              Date {sortBy === 'date' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-4 py-2 border-b cursor-pointer" onClick={() => handleSort('name')}>
              Name {sortBy === 'name' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-4 py-2 border-b cursor-pointer" onClick={() => handleSort('callerPhoneNumber')}>
              Phone Number {sortBy === 'callerPhoneNumber' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-4 py-2 border-b cursor-pointer" onClick={() => handleSort('propertyId')}>
              Property {sortBy === 'propertyId' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-4 py-2 border-b cursor-pointer" onClick={() => handleSort('status')}>
              Status {sortBy === 'status' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-4 py-2 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="px-4 py-2 border-b">{new Date(appointment.date).toLocaleDateString()}</td>
                <td className="px-4 py-2 border-b">{appointment.name}</td>
                <td className="px-4 py-2 border-b">{appointment.callerPhoneNumber || 'N/A'}</td>
                <td className="px-4 py-2 border-b">{appointment.propertyId}</td>
                <td className="px-4 py-2 border-b">
                  {appointment.status ? (
                    <span className={`px-2 py-1 text-sm rounded-lg ${statusColors[appointment.status]}`}>
                      {appointment.status}
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-sm text-gray-500">N/A</span>
                  )}
                </td>
                <td className="px-4 py-2 border-b flex space-x-4">
                  <button className="text-blue-600 hover:text-blue-800">
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                  <button className="text-green-600 hover:text-green-800">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="text-red-600 hover:text-red-800">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="px-4 py-2 text-center text-gray-500">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-4 space-x-4">
        <div>
          <label className="text-gray-600">Records per page:</label>
          <select
            value={pageSize}
            onChange={handlePageSizeChange}
            className="ml-2 border rounded-md px-2 py-1"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="text-gray-600 hover:text-gray-800 disabled:text-gray-400"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`mx-1 px-2 py-1 rounded ${
                currentPage === i + 1 ? 'bg-teal-500 text-white' : 'bg-gray-100 text-gray-800'
              }`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="text-gray-600 hover:text-gray-800 disabled:text-gray-400"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className="text-gray-600">
          Page {currentPage} of {totalPages}
        </div>
      </div>
    </div>
  );
};

export default AppointmentsPage;
