import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faCalendarCheck, faCog } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

interface UserInfo {
  name: string;
  role: string;
}

const parseAccessToken = (token: string): UserInfo | null => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return {
      name: payload.name,
      role: payload.role,
    };
  } catch (error) {
    console.error('Error parsing access token:', error);
    return null;
  }
};

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
};

const Sidebar: React.FC = () => {
  const token = localStorage.getItem('accessToken');
  const userInfo = token ? parseAccessToken(token) : null;

  return (
    <div className="bg-gray-800 text-white w-64 p-6 flex flex-col items-center">
      {/* Avatar */}
      {userInfo ? (
        <div
          className="text-white rounded-full w-36 h-36 mb-4 flex items-center justify-center text-3xl font-bold"
          style={{
            backgroundImage: `linear-gradient(45deg, #2D3748 25%, transparent 25%, transparent 50%, #2D3748 50%, #2D3748 75%, transparent 75%, transparent)`,
            backgroundSize: '20px 20px',
            backgroundColor: '#2D3748',
            color: '#81E6D9',
          }}
        >
          {getInitials(userInfo.name)}
        </div>
      ) : (
        <div className="bg-gray-600 text-white rounded-full w-36 h-36 mb-4 flex items-center justify-center text-3xl font-bold">
          ?
        </div>
      )}
      {/* User Info */}
      <h2 className="text-xl font-semibold">{userInfo ? userInfo.name : 'Guest'}</h2>
      <p className="text-gray-400">{userInfo ? userInfo.role : 'Role'}</p>

      {/* Divider */}
      <div className="w-full border-t border-gray-700 my-4"></div>

      {/* Navigation Links */}
      <nav className="w-full flex flex-col space-y-2">
        <NavLink
          to="/home/dashboard"
          className={({ isActive }) =>
            `flex items-center space-x-2 py-2 px-4 rounded transition-colors ${isActive ? 'bg-teal-600' : 'hover:bg-gray-700'
            }`
          }
        >
          <FontAwesomeIcon icon={faHome} />
          <span>Home</span>
        </NavLink>
        <NavLink
          to="/home/calls"
          className={({ isActive }) =>
            `flex items-center space-x-2 py-2 px-4 rounded transition-colors ${isActive ? 'bg-teal-600' : 'hover:bg-gray-700'
            }`
          }
        >
          <FontAwesomeIcon icon={faPhone} />
          <span>Calls</span>
        </NavLink>
        <NavLink
          to="/home/appointments"
          className={({ isActive }) =>
            `flex items-center space-x-2 py-2 px-4 rounded transition-colors ${isActive ? 'bg-teal-600' : 'hover:bg-gray-700'
            }`
          }
        >
          <FontAwesomeIcon icon={faCalendarCheck} />
          <span>Appointments</span>
        </NavLink>
        <NavLink
          to="/home/settings"
          className={({ isActive }) =>
            `flex items-center space-x-2 py-2 px-4 rounded transition-colors ${isActive ? 'bg-teal-600' : 'hover:bg-gray-700'
            }`
          }
        >
          <FontAwesomeIcon icon={faCog} />
          <span>Settings</span>
        </NavLink>
      </nav>
    </div>
  );
};

export default Sidebar;
