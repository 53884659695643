import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Link } from 'react-router-dom';

const DashboardPage: React.FC = () => {
  // Mock data for the chart
  const chartData = {
    labels: Array.from({ length: 90 }, (_, i) => `Day ${i + 1}`),
    datasets: [
      {
        label: 'Calls',
        data: Array.from({ length: 90 }, () => Math.floor(Math.random() * 100)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        barThickness: 8,
      },
    ],
  };

  // Options for the chart
  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Dashboard</h1>

      {/* Metrics Section */}
      <div className="grid grid-cols-3 gap-6 mb-8">
        <div className="bg-blue-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-blue-600 mb-2">150</h2>
          <p className="text-lg font-medium text-gray-700">Calls Today</p>
        </div>
        <div className="bg-green-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-green-600 mb-2">1,050</h2>
          <p className="text-lg font-medium text-gray-700">Calls This Week</p>
        </div>
        <div className="bg-purple-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-purple-600 mb-2">4,500</h2>
          <p className="text-lg font-medium text-gray-700">Calls This Month</p>
        </div>
      </div>

      {/* Bar Chart Section */}
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Calls Over the Last 90 Days</h2>
        <Bar data={chartData} options={chartOptions} />
      </div>

      {/* Latency Metrics Section */}
      <div className="grid grid-cols-3 gap-6 mb-8">
        <div className="bg-yellow-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-yellow-600 mb-2">120ms</h2>
          <p className="text-lg font-medium text-gray-700">ChatGPT Latency</p>
        </div>
        <div className="bg-red-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-red-600 mb-2">80ms</h2>
          <p className="text-lg font-medium text-gray-700">Deepgram Latency</p>
        </div>
        <div className="bg-teal-100 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-extrabold text-teal-600 mb-2">95ms</h2>
          <p className="text-lg font-medium text-gray-700">11Labs Latency</p>
        </div>
      </div>

      {/* Latest Appointments Table */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Latest Appointments</h2>
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">Date</th>
              <th className="px-4 py-2 border-b">Client</th>
              <th className="px-4 py-2 border-b">Property</th>
              <th className="px-4 py-2 border-b">Agent</th>
              <th className="px-4 py-2 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border-b">2024-08-07</td>
              <td className="px-4 py-2 border-b">John Doe</td>
              <td className="px-4 py-2 border-b">123 Main St</td>
              <td className="px-4 py-2 border-b">Jane Smith</td>
              <td className="px-4 py-2 border-b">Confirmed</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
        <div className="text-right mt-4">
          <Link to="/appointments" className="text-blue-600 hover:underline">
            View All Appointments
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
