export const mapApiDataToFormValues = (apiData: any) => {
  return {
    appServerHost: apiData.APP_SERVER_HOST || '',
    recordingEnabled: apiData.RECORDING_ENABLED || false,
    port: apiData.PORT || 3000,
    sslPort: apiData.SSL_PORT || 3443,
    onlyHttp: apiData.ONLY_HTTP || false,
    openaiApiKey: apiData.OPENAI_API_KEY || '',
    openaiModel: apiData.OPENAI_MODEL || '',
    groqApiKey: apiData.GROQ_API_KEY || '',
    databaseUsername: apiData.DATABASE_USERNAME || '',
    databasePassword: apiData.DATABASE_PASSWORD || '',
    databaseName: apiData.DATABASE_NAME || '',
    databaseHost: apiData.DATABASE_HOST || '',
    databasePort: apiData.DATABASE_PORT || 3306,
    databaseDialect: apiData.DATABASE_DIALECT || 'mysql',
    databaseLogging: apiData.DATABASE_LOGGING || false,
    deepgramApiKey: apiData.DEEPGRAM_API_KEY || '',
    deepgramModel: apiData.DEEPGRAM_MODEL || '',
    deepgramSmartFormat: apiData.DEEPGRAM_SMART_FORMAT || false,
    deepgramPunctuate: apiData.DEEPGRAM_PUNCTUATE || false,
    deepgramEndpointing: apiData.DEEPGRAM_ENDPOINTING || false,
    deepgramInterimResults: apiData.DEEPGRAM_INTERIM_RESULTS || false,
    voiceModel: apiData.VOICE_MODEL || '',
    deepgramLanguage: apiData.DEEPGRAM_LANGUAGE || '',
    utteranceEndMs: apiData.UTTERANCE_END_MS || 2000,
    twilioAccountSid: apiData.TWILIO_ACCOUNT_SID || '',
    twilioAuthToken: apiData.TWILIO_AUTH_TOKEN || '',
    elevenLabsApiKey: apiData.ELEVEN_LABS_API_KEY || '',
    elevenLabsModelId: apiData.ELEVEN_LABS_MODEL_ID || '',
    elevenLabsVoiceId: apiData.ELEVEN_LABS_VOICE_ID || '',
    elevenLabsLanguage: apiData.ELEVEN_LABS_LANGUAGE || '',
    playhtUserId: apiData.PLAYHT_USER_ID || '',
    playhtSecretKey: apiData.PLAYHT_SECRET_KEY || '',
    playhtVoice: apiData.PLAYHT_VOICE || '',
    slackAiTelecallerAlertUrl: apiData.SLACK_AI_TELECALLER_ALERT_URL || '',
    assistantLanguage: apiData.ASSISTANT_LANGUAGE || '',
    assistantName: apiData.ASSISTANT_NAME || '',
    agencyName: apiData.AGENCY_NAME || '',
  };
};

export const mapFormValuesToApiData = (formValues: any) => {
  return {
    APP_SERVER_HOST: formValues.appServerHost,
    RECORDING_ENABLED: formValues.recordingEnabled,
    PORT: formValues.port,
    SSL_PORT: formValues.sslPort,
    ONLY_HTTP: formValues.onlyHttp,
    OPENAI_API_KEY: formValues.openaiApiKey,
    OPENAI_MODEL: formValues.openaiModel,
    GROQ_API_KEY: formValues.groqApiKey,
    DATABASE_USERNAME: formValues.databaseUsername,
    DATABASE_PASSWORD: formValues.databasePassword,
    DATABASE_NAME: formValues.databaseName,
    DATABASE_HOST: formValues.databaseHost,
    DATABASE_PORT: formValues.databasePort,
    DATABASE_DIALECT: formValues.databaseDialect,
    DATABASE_LOGGING: formValues.databaseLogging,
    DEEPGRAM_API_KEY: formValues.deepgramApiKey,
    DEEPGRAM_MODEL: formValues.deepgramModel,
    DEEPGRAM_SMART_FORMAT: formValues.deepgramSmartFormat,
    DEEPGRAM_PUNCTUATE: formValues.deepgramPunctuate,
    DEEPGRAM_ENDPOINTING: formValues.deepgramEndpointing,
    DEEPGRAM_INTERIM_RESULTS: formValues.deepgramInterimResults,
    DEEPGRAM_LANGUAGE: formValues.deepgramLanguage,
    VOICE_MODEL: formValues.voiceModel,
    UTTERANCE_END_MS: formValues.utteranceEndMs,
    TWILIO_ACCOUNT_SID: formValues.twilioAccountSid,
    TWILIO_AUTH_TOKEN: formValues.twilioAuthToken,
    ELEVEN_LABS_API_KEY: formValues.elevenLabsApiKey,
    ELEVEN_LABS_MODEL_ID: formValues.elevenLabsModelId,
    ELEVEN_LABS_VOICE_ID: formValues.elevenLabsVoiceId,
    ELEVEN_LABS_LANGUAGE: formValues.elevenLabsLanguage,
    PLAYHT_USER_ID: formValues.playhtUserId,
    PLAYHT_SECRET_KEY: formValues.playhtSecretKey,
    PLAYHT_VOICE: formValues.playhtVoice,
    SLACK_AI_TELECALLER_ALERT_URL: formValues.slackAiTelecallerAlertUrl,
    ASSISTANT_LANGUAGE: formValues.assistantLanguage,
    ASSISTANT_NAME: formValues.assistantName,
    AGENCY_NAME: formValues.agencyName,
  };
};
