import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCallSessionById } from '../services/callSessionService';
import FullPageSpinner from '../components/FullPageSpinner';
import Toast from '../components/Toast';

interface Conversation {
  id: number;
  content: string;
  role: string;
  createdAt: string;
}

interface CallSession {
  id: string;
  callerNumber: string;
  calledNumber: string;
  assistant: string;
  contextualData: string;
  language: string;
  voice: string;
  createdAt: string;
  updatedAt: string;
  conversations: Conversation[];
}

const CallDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [callSession, setCallSession] = useState<CallSession | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getCallSession = async () => {
      try {
        const data = await fetchCallSessionById(id!);
        setCallSession(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch call session details.');
        setLoading(false);
      }
    };

    getCallSession();
  }, [id]);

  const handleCloseToast = () => {
    setError(null);
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {error && (
        <Toast
          message={error}
          severity="error"
          isVisible={!!error}
          onClose={handleCloseToast}
        />
      )}

      {callSession && (
        <>
          {/* Call Session Details Card */}
          <h2 className="text-2xl font-bold mb-4">Call Details</h2>
          <div className="mb-4 p-4 border border-gray-200 rounded-lg shadow-sm">
            <p><strong>Caller Number:</strong> {callSession.callerNumber}</p>
            <p><strong>Called Number:</strong> {callSession.calledNumber}</p>
            <p><strong>Assistant:</strong> {callSession.assistant}</p>
            <p><strong>Language:</strong> {callSession.language}</p>
            <p><strong>Voice:</strong> {callSession.voice}</p>
            <p><strong>Contextual Data:</strong> {callSession.contextualData}</p>
            <p><strong>Created At:</strong> {new Date(callSession.createdAt).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(callSession.updatedAt).toLocaleString()}</p>
          </div>

          {/* Conversations */}
          <h3 className="text-xl font-semibold mb-4">Conversations</h3>
          <div className="space-y-4">
            {callSession.conversations.map((conversation) => (
              <div
                key={conversation.id}
                className={`flex ${
                  conversation.role === 'user'
                    ? 'justify-start'
                    : conversation.role === 'assistant'
                    ? 'justify-end'
                    : 'justify-center'
                }`}
              >
                <div
                  className={`w-3/5 p-4 rounded-lg ${
                    conversation.role === 'user'
                      ? 'bg-blue-500 text-white'
                      : conversation.role === 'assistant'
                      ? 'bg-green-500 text-white'
                      : 'bg-yellow-400 text-black'
                  }`}
                  style={{
                    borderRadius:
                      conversation.role === 'user'
                        ? '15px 15px 15px 0'
                        : conversation.role === 'assistant'
                        ? '15px 15px 0 15px'
                        : '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <p className="whitespace-pre-line">{conversation.content || '<No content>'}</p>
                  <p className="text-xs text-right mt-2 text-gray-600">
                    {new Date(conversation.createdAt).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CallDetailsPage;
