import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import SettingsPage from './SettingsPage';
import Sidebar from '../components/Sidebar';
import CallsPage from './CallsPage';
import CallDetailsPage from './CallDetailsPage';
import DashboardPage from './Dashboard';
import AppointmentsPage from './AppointmentsPage';

const HomePage: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.href = '/';
  };

  return (
    <div className="max-h-screen flex">
      <div
        className={`bg-gray-800 text-white space-y-6 py-7 absolute inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } transition duration-200 ease-in-out z-50`}
      >
        <Sidebar />
      </div>

      <div className={`flex-1 flex flex-col transition-transform duration-200 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <header className="bg-teal-600 text-white shadow-md flex justify-between items-center px-5 py-4">
          <button onClick={toggleSidebar} className="focus:outline-none text-white">
            <FontAwesomeIcon icon={faBars} className="w-5 h-5" />
          </button>
          <button onClick={logout} className="focus:outline-none text-white">
            <FontAwesomeIcon icon={faSignOutAlt} className="w-5 h-5" />
          </button>
        </header>


        <div className="flex-1 overflow-hidden">
          <main className="p-6 bg-gray-100 h-full overflow-y-auto">
            <Routes>
              <Route path="" element={<DashboardPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="calls" element={<CallsPage />} />
              <Route path="calls/:id" element={<CallDetailsPage />} />
              <Route path="appointments" element={<AppointmentsPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
