import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { fetchSettings, saveSettings } from '../services/configService';
import { mapApiDataToFormValues, mapFormValuesToApiData } from '../utils/settingsUtils';
import FullPageSpinner from '../components/FullPageSpinner';
import Toast from '../components/Toast';

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState<'success' | 'error'>('success');
  const [voices, setVoices] = useState([]);
  const [initialValues, setInitialValues] = useState({
    appServerHost: '',
    recordingEnabled: false,
    openaiApiKey: '',
    openaiModel: 'gpt-4o-mini',
    groqApiKey: '',
    utteranceEndMs: 2000,
    deepgramApiKey: '',
    deepgramModel: 'nova-2-general',
    deepgramSmartFormat: true,
    deepgramPunctuate: true,
    deepgramEndpointing: '',
    deepgramInterimResults: true,
    voiceModel: 'aura-asteria-en',
    deepgramLanguage: 'en',
    twilioAccountSid: '',
    twilioAuthToken: '',
    elevenLabsApiKey: '',
    elevenLabsModelId: 'eleven_multilingual_v2',
    elevenLabsVoiceId: 'XrExE9yKIg1WjnnlVkGX',
    elevenLabsLanguage: 'en',
    playhtUserId: '',
    playhtSecretKey: '',
    playhtVoice: '',
    slackAiTelecallerAlertUrl: '',
    assistantLanguage: 'en',
    assistantName: 'Maya',
    agencyName: 'Casteels vastgoed',
  });

  const showToast = (message: string, severity: 'success' | 'error') => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastVisible(true);
    setTimeout(() => setToastVisible(false), 3000);
  };

  useEffect(() => {
    const loadSettings = async () => {
      setLoading(true);
      try {
        const data = await fetchSettings();
        setVoices(data.voices);
        formik.setValues(mapApiDataToFormValues(data));
        console.log(mapApiDataToFormValues(data));
      } catch (error) {
        showToast('Failed to load settings.', 'error');
        console.error('Error fetching settings', error);
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await saveSettings(mapFormValuesToApiData(values));
        showToast('Settings saved successfully!', 'success');
      } catch (error) {
        showToast('Failed to save settings.', 'error');
        console.error('Error saving settings', error);
      } finally {
        setLoading(false);
      }
    },
  });

  const renderTabContent = () => {
    switch (activeTab) {
      case 'openai':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="openaiApiKey" className="block text-gray-700 mb-2">OpenAI API Key</label>
            <input
              id="openaiApiKey"
              name="openaiApiKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.openaiApiKey}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="openaiModel" className="block text-gray-700 mb-2">OpenAI Model</label>
            <select
              id="openaiModel"
              name="openaiModel"
              onChange={formik.handleChange}
              value={formik.values.openaiModel}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
              <option value="gpt-4o">gpt-4o</option>
              <option value="gpt-4o-mini">gpt-4o-mini</option>
            </select>
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'groq':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="groqApiKey" className="block text-gray-700 mb-2">Groq API Key</label>
            <input
              id="groqApiKey"
              name="groqApiKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.groqApiKey}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'deepgram':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="deepgramApiKey" className="block text-gray-700 mb-2">Deepgram API Key</label>
            <input
              id="deepgramApiKey"
              name="deepgramApiKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.deepgramApiKey}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="deepgramModel" className="block text-gray-700 mb-2">Deepgram Model</label>
            <select
              id="deepgramModel"
              name="deepgramModel"
              onChange={formik.handleChange}
              value={formik.values.deepgramModel}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="nova-2-general">nova-2-general</option>
              <option value="nova-2-phonecall">nova-2-phonecall</option>
              <option value="nova-2-conversationalai">nova-2-conversationalai</option>
              <option value="nova-2-meeting">nova-2-meeting</option>
              <option value="whisper">whisper</option>
            </select>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="flex items-center">
              <input
                id="deepgramSmartFormat"
                name="deepgramSmartFormat"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.deepgramSmartFormat}
                className="h-5 w-5 text-blue-600"
              />
              <label htmlFor="deepgramSmartFormat" className="ml-2 text-gray-700">Smart Format</label>
            </div>
            <div className="flex items-center">
              <input
                id="deepgramPunctuate"
                name="deepgramPunctuate"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.deepgramPunctuate}
                className="h-5 w-5 text-blue-600"
              />
              <label htmlFor="deepgramPunctuate" className="ml-2 text-gray-700">Punctuate</label>
            </div>
            <div className="flex items-center">
              <input
                id="deepgramInterimResults"
                name="deepgramInterimResults"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.deepgramInterimResults}
                className="h-5 w-5 text-blue-600"
              />
              <label htmlFor="deepgramInterimResults" className="ml-2 text-gray-700">Interim Results</label>
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="deepgramEndpointing" className="block text-gray-700 mb-2">Endpointing (ms)</label>
            <input
              id="deepgramEndpointing"
              name="deepgramEndpointing"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.deepgramEndpointing}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="voiceModel" className="block text-gray-700 mb-2">Voice Model</label>
            <select
              id="voiceModel"
              name="voiceModel"
              onChange={formik.handleChange}
              value={formik.values.voiceModel}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="aura-asteria-en">aura-asteria-en</option>
              <option value="aura-luna-en">aura-luna-en</option>
              <option value="aura-stella-en">aura-stella-en</option>
              <option value="aura-athena-en">aura-athena-en</option>
              <option value="aura-hera-en">aura-hera-en</option>
              <option value="aura-orion-en">aura-orion-en</option>
              <option value="aura-arcas-en">aura-arcas-en</option>
              <option value="aura-perseus-en">aura-perseus-en</option>
              <option value="aura-angus-en">aura-angus-en</option>
              <option value="aura-orpheus-en">aura-orpheus-en</option>
              <option value="aura-helios-en">aura-helios-en</option>
              <option value="aura-zeus-en">aura-zeus-en</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="utteranceEndMs" className="block text-gray-700 mb-2">Utterance End (ms)</label>
            <input
              id="utteranceEndMs"
              name="utteranceEndMs"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.utteranceEndMs}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="deepgramLanguage" className="block text-gray-700 mb-2">Language</label>
            <select
              id="deepgramLanguage"
              name="deepgramLanguage"
              onChange={formik.handleChange}
              value={formik.values.deepgramLanguage}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="en-US">English</option>
              <option value="nl-BE">Flemish</option>
              <option value="nl">Dutch</option>
              <option value="fr">French</option>
            </select>
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'general':
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="appServerHost" className="block text-gray-700 mb-2">App Server Host</label>
              <input
                id="appServerHost"
                name="appServerHost"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.appServerHost}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="recordingEnabled" className="block text-gray-700 mb-2">Recording Enabled</label>
              <input
                id="recordingEnabled"
                name="recordingEnabled"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.recordingEnabled}
                className="h-5 w-5 text-blue-600"
              />
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Save Settings
            </button>
          </form>
        );
      case 'twilio':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="twilioAccountSid" className="block text-gray-700 mb-2">Twilio Account SID</label>
            <input
              id="twilioAccountSid"
              name="twilioAccountSid"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.twilioAccountSid}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="twilioAuthToken" className="block text-gray-700 mb-2">Twilio Auth Token</label>
            <input
              id="twilioAuthToken"
              name="twilioAuthToken"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.twilioAuthToken}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'elevenLabs':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="elevenLabsApiKey" className="block text-gray-700 mb-2">11 Labs API Key</label>
            <input
              id="elevenLabsApiKey"
              name="elevenLabsApiKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.elevenLabsApiKey}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="elevenLabsModelId" className="block text-gray-700 mb-2">11 Labs Model</label>
            <select
              id="elevenLabsModelId"
              name="elevenLabsModelId"
              onChange={formik.handleChange}
              value={formik.values.elevenLabsModelId}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="eleven_turbo_v2_5">eleven_turbo_v2_5</option>
              <option value="eleven_multilingual_v2">eleven_multilingual_v2</option>
              <option value="eleven_turbo_v2">eleven_turbo_v2</option>
              <option value="eleven_monolingual_v1">eleven_monolingual_v1</option>
              <option value="eleven_multilingual_v1">eleven_multilingual_v1</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="elevenLabsVoiceId" className="block text-gray-700 mb-2">11 Labs Voice ID</label>
            <select
              id="elevenLabsVoiceId"
              name="elevenLabsVoiceId"
              onChange={formik.handleChange}
              value={formik.values.elevenLabsVoiceId}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {voices.map((voice: any) => <option key={voice.voice_id} value={voice.voice_id}>{voice.name}</option>)}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="elevenLabsLanguage" className="block text-gray-700 mb-2">Language</label>
            <select
              id="elevenLabsLanguage"
              name="elevenLabsLanguage"
              onChange={formik.handleChange}
              value={formik.values.elevenLabsLanguage}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="en">English</option>
              <option value="nl">Dutch</option>
              <option value="fr">French</option>
            </select>
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'playht':
        return (<form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="playhtUserId" className="block text-gray-700 mb-2">PlayHT User ID</label>
            <input
              id="playhtUserId"
              name="playhtUserId"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.playhtUserId}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="playhtSecretKey" className="block text-gray-700 mb-2">PlayHT Secret Key</label>
            <input
              id="playhtSecretKey"
              name="playhtSecretKey"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.playhtSecretKey}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="playhtVoice" className="block text-gray-700 mb-2">PlayHT Voice</label>
            <input
              id="playhtVoice"
              name="playhtVoice"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.playhtVoice}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
            Save Settings
          </button>
        </form>);
      case 'slack':
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="slackAiTelecallerAlertUrl" className="block text-gray-700 mb-2">Slack AI Telecaller Alert URL</label>
              <input
                id="slackAiTelecallerAlertUrl"
                name="slackAiTelecallerAlertUrl"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.slackAiTelecallerAlertUrl}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Save Settings
            </button>
          </form>
        );
      case 'miscellaneous':
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="assistantLanguage" className="block text-gray-700 mb-2">Assistant Language</label>
              <select
                id="assistantLanguage"
                name="assistantLanguage"
                onChange={formik.handleChange}
                value={formik.values.assistantLanguage}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="nl">Dutch</option>
                <option value="hi">Hindi</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="assistantName" className="block text-gray-700 mb-2">Assistant Name</label>
              <input
                id="assistantName"
                name="assistantName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.assistantName}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="agencyName" className="block text-gray-700 mb-2">Agency Name</label>
              <input
                id="agencyName"
                name="agencyName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.agencyName}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Save Settings
            </button>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative bg-gray-100 p-4">
      {loading && <FullPageSpinner />}
      <Toast
        message={toastMessage}
        severity={toastSeverity}
        isVisible={toastVisible}
        onClose={() => setToastVisible(false)}
      />
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6">Settings</h2>
        <div className="mb-6">
          <ul className="flex border-b">
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('general')}
                className={`inline-block py-2 px-4 ${activeTab === 'general' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                General
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('openai')}
                className={`inline-block py-2 px-4 ${activeTab === 'openai' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                OpenAI
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('groq')}
                className={`inline-block py-2 px-4 ${activeTab === 'groq' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                Groq
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('deepgram')}
                className={`inline-block py-2 px-4 ${activeTab === 'deepgram' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                Deepgram (Speech - Text)
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('twilio')}
                className={`inline-block py-2 px-4 ${activeTab === 'twilio' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                Twilio
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('elevenLabs')}
                className={`inline-block py-2 px-4 ${activeTab === 'elevenLabs' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                11 Labs (Text - Speech)
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('playht')}
                className={`inline-block py-2 px-4 ${activeTab === 'playht' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                PlayHT
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('slack')}
                className={`inline-block py-2 px-4 ${activeTab === 'slack' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                Slack
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setActiveTab('miscellaneous')}
                className={`inline-block py-2 px-4 ${activeTab === 'miscellaneous' ? 'text-black font-bold' : 'text-blue-500 hover:text-blue-700'}`}
              >
                Miscellaneous
              </button>
            </li>
          </ul>
        </div>
        <div>{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default SettingsPage;
